.contactUsFormContainer {
    padding: 20px;
    margin: 50px auto;
    max-width: 600px;
}

.contactUsFormTitle {
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    margin: 50px 20px;
}

.contactUsFormBtn{
    height: 50px;
    margin: 0 140px;
    font-size: 1.2em;
}

@media only screen and ( max-width: 40em ) {
    .contactUsFormBtn{
        height: 50px;
        margin: 0;
    }
}