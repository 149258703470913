.contactUsMessageContainer {
    padding: 20px;
    margin: 50px auto;
    max-width: 600px;
}

.errorMessage {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 250px
}

.errorMessageIcon {
    max-width: 75px;
}

.errorMessageTitle {
    color: #ec5645;
    transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

.errorMessageContent {
    color: #B8BABB;
    transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

.errorMessageContent a{
    color: #00A0C6;
}