/* header */

.header {
    display: block;
    margin: 0 0 20px 0;
    padding: 30px 15px 0 15px;
    border-bottom: 2px #52078e double;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: flex;
    align-items: center;
}

/*.header ul li:last-child {
    margin-left: 40px;
}*/

.header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 30px 20px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

@media (min-width: 768px) {
    .header li {
        float: left;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header .menu {
        max-height: none;
    }
    .header .menu-icon {
        display: none;
    }
}
@media (max-width: 960px) and (min-width: 768px) {
    .header {
        font-size: 12px;
        display: block;
    }
}