@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,500|Raleway:400,700,400italic,700italic);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F4EEFC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* header */

.header {
    display: block;
    margin: 0 0 20px 0;
    padding: 30px 15px 0 15px;
    border-bottom: 2px #52078e double;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: flex;
    align-items: center;
}

/*.header ul li:last-child {
    margin-left: 40px;
}*/

.header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 30px 20px;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

@media (min-width: 768px) {
    .header li {
        float: left;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header .menu {
        max-height: none;
    }
    .header .menu-icon {
        display: none;
    }
}
@media (max-width: 960px) and (min-width: 768px) {
    .header {
        font-size: 12px;
        display: block;
    }
}
.logoContainer {
  display: flex;
  justify-content: center;
}

#logoContent {
  width: 300px;
  height: 100px;
}
.align-items {
    align-items: center;
}
.homePage {
    background-color: #f5f4f8;
}
.carouselContainer {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
}

.carouselContainer img {
    margin: 0;
    padding: 0;
    width: 100%;
}
.cardContainer {
    max-width: 345px;
    min-width: 250px;
    height: 340px;
    margin: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.modalContainer {
    padding: 10px;
}

.modalContainer h6 {
    margin-top: 10px;
}

.contactUsToday {
   border-top: 1px dashed #1f79f640;
   margin-top: 30px;
}

@media screen and (max-width: 768px) {
.modalContainer {
    padding: 0;
}
}
.bestSellersContainer{
    height: auto;
    width: 100%;
    margin: 10px 0;
}

.bestSellersTitle{
    width: 100%;
    clear: both;
    margin: 0;
    padding: 0;
}

.aboutUsContainer{
    height: auto;
    width: 100%;
    margin: 10px 0;
}

.aboutUsTitle{
    width: 100%;
    clear: both;
    margin: 0;
    padding: 0;
}

.aboutUsImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutUsContentContainer {
    border-right: 1px #888888 solid;
    font-size: 1.2em;
}

.aboutUsContentContainer p{
    padding: 0 50px;
}

@media screen and (max-width: 768px) {
    .aboutUsContentContainer {
        border-right: none;
        padding-left: 15px;
        font-size: 1em;
    }
    .aboutUsContentContainer p{
        padding: 0;
    }
}
body {
    clear: both;
    height: 800px;
    width: 100%;
}

.contentContainer {
    padding: 15px;
}
.badgeContainer {
    display: flex;
}

.badgeContainer img{
    width: 100px;
    height: 100px;
    margin: 15px;
}

.badgeContent {
    font-size: 0.8em;
}

.badgeContent h5 {
    font-weight: bold;
}
.customizationProcessIcon{
    width: 100px;
    height: 100px;
    margin-left: 50px;
}

h4 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

ul,
ol {
    margin: 1em 0;
}

ol.process-chart {
    color: #000;
    counter-reset: item;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0 1em;
    padding: 0;
}

ol.process-chart > li,
ol.process-chart > li > div {
    position: relative;
}

ol.process-chart > li {
    background: linear-gradient(to right, rgba(0,0,0,0.25) 0%, transparent 25%);
    background-color: #fff;
    counter-increment: item;
    flex-basis: 0;
    flex-grow: 1;
    margin: 1em 1em 1em -1em;
    min-width: 11.25em;
    padding: 1em 1em 1em 2em;
}

ol.process-chart > li:before,
ol.process-chart > li > div:before {
    color: rgba(0,0,0,0.75);
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

ol.process-chart > li:before {
    content: 'Step';
    display: inline-block;
    font-weight: 300;
    left: 2em;
    letter-spacing: 0.1em;
    position: absolute;
    top: 1em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 25% 50%;
            transform-origin: 25% 50%;
}

ol.process-chart > li:after {
    border-bottom: 1em solid transparent;
    border-left: 1em solid #fff;
    border-right: 0 solid transparent;
    border-top: 1em solid transparent;
    content: '';
    height: 100%;
    position: absolute;
    right: -1em;
    top: 0;
    width: 0;
    z-index: 1;
}

ol.process-chart > li > div {
    padding: 0 0 0 1em;
}

ol.process-chart > li > div:before {
    content: counter(item, decimal-leading-zero);
    font-size: 3em;
    font-weight: 500;
    line-height: 1;
}

ol.process-chart > li:nth-of-type(6n+1) {
    background-color: #a2ed56;
}

ol.process-chart > li:nth-of-type(6n+1):after {
    border-left-color: #a2ed56;
}

ol.process-chart > li:nth-of-type(6n+2) {
    background-color: #83e4e2;
}

ol.process-chart > li:nth-of-type(6n+2):after {
    border-left-color: #83e4e2;
}

ol.process-chart > li:nth-of-type(6n+3) {
    background-color: #fd6470;
}

ol.process-chart > li:nth-of-type(6n+3):after {
    border-left-color: #fd6470;
}

ol.process-chart > li:nth-of-type(6n+4) {
    background-color: #fca858;
}

ol.process-chart > li:nth-of-type(6n+4):after {
    border-left-color: #fca858;
}

ol.process-chart > li:nth-of-type(6n+5) {
    background-color: #fddc32;
}

ol.process-chart > li:nth-of-type(6n+5):after {
    border-left-color: #fddc32;
}

ol.process-chart > li:nth-of-type(6n+6) {
    background-color: #ab9ace;
}

ol.process-chart > li:nth-of-type(6n+6):after {
    border-left-color: #ab9ace;
}

/*** MEDIA QUERIES ***/
@media only screen and ( max-width: 40em ) {

    ol.process-chart {
        margin: 1em 0 0 0;
        padding: 1em 0 0 0;
    }

    ol.process-chart > li {
        background: linear-gradient(rgba(0,0,0,0.25) 0%, transparent 25%);
        flex-basis: inherit;
        margin: -1em 0 1em 0;
        min-width: inherit;
        padding: 1em 1em 0 1em;
    }

    ol.process-chart > li:before {
        left: 1em;
        top: 2em;
    }

    ol.process-chart > li:after {
        border-bottom: 0 solid transparent;
        border-left: 1em solid transparent!important;
        border-right: 1em solid transparent;
        border-top: 1em solid #fff;
        bottom: -1em;
        height: 0;
        left: 0;
        right: inherit;
        top: inherit;
        width: 100%;
    }

    ol.process-chart > li > div {
        padding: 1em 1em 0 1em;
    }

    ol.process-chart > li:nth-of-type(6n+1):after {
        border-top-color: #a2ed56;
    }

    ol.process-chart > li:nth-of-type(6n+2):after {
        border-top-color: #83e4e2;
    }

    ol.process-chart > li:nth-of-type(6n+3):after {
        border-top-color: #fd6470;
    }

    ol.process-chart > li:nth-of-type(6n+4):after {
        border-top-color: #fca858;
    }

    ol.process-chart > li:nth-of-type(6n+5):after {
        border-top-color: #fddc32;
    }

    ol.process-chart > li:nth-of-type(6n+6):after {
        border-left-color: #ab9ace;
    }
}
.notificationBar {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    position: fixed;
    z-index: 1000;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color:#00A0C6;
    text-decoration:none;
    cursor:pointer;
}
.productsPage {
    background-color: #f5f4f8;
}
.productsContentContainer {
    background-color: #f5f4f8;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: row;
}

.productsByCategoriesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

@media screen and (max-width: 768px) {
    .productsContentContainer {
        background-color: #f5f4f8;
        padding: 0;
    }
    .productsByCategoriesContainer {
        margin: 0;
    }
}
.productSiderContainer {
    display: block;
}

.productSiderContainer ul{
    background-color: #ffffff;
}

@media screen and (max-width: 768px) {
    .productSiderContainer {
        display: none;
    }
}
.categoriesContainer {
    padding: 0 15px 15px 15px;
}

.productsByCategoriesContainer h3{
    font-family: 'Oswald', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 20px 0;
}

@media screen and (max-width: 768px) {
    .productsContentContainer {
        background-color: #f5f4f8;
        padding: 0;
    }
    .productsByCategoriesContainer h3{
        margin: 10px 0;
        font-family: 'Oswald', sans-serif;
        font-size: 20px;
        font-weight: 400;
    }
}
.contactUs {
    background-color: #f5f4f8;
}
body {
    clear: both;
    height: 800px;
    width: 100%;
}

.contentContainer {
    padding: 15px;
}
.contactUsFormContainer {
    padding: 20px;
    margin: 50px auto;
    max-width: 600px;
}

.contactUsFormTitle {
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    margin: 50px 20px;
}

.contactUsFormBtn{
    height: 50px;
    margin: 0 140px;
    font-size: 1.2em;
}

@media only screen and ( max-width: 40em ) {
    .contactUsFormBtn{
        height: 50px;
        margin: 0;
    }
}
.contactUsMessageContainer {
    padding: 20px;
    margin: 50px auto;
    max-width: 600px;
}

.successMessage {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 250px
}

.successMessageIcon {
    max-width: 75px;
}

.successMessageTitle {
    color: #3DC480;
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

.successMessageContent {
    color: #B8BABB;
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}
.contactUsMessageContainer {
    padding: 20px;
    margin: 50px auto;
    max-width: 600px;
}

.errorMessage {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 250px
}

.errorMessageIcon {
    max-width: 75px;
}

.errorMessageTitle {
    color: #ec5645;
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

.errorMessageContent {
    color: #B8BABB;
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

.errorMessageContent a{
    color: #00A0C6;
}
