.categoriesContainer {
    padding: 0 15px 15px 15px;
}

.productsByCategoriesContainer h3{
    font-family: 'Oswald', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 20px 0;
}

@media screen and (max-width: 768px) {
    .productsContentContainer {
        background-color: #f5f4f8;
        padding: 0;
    }
    .productsByCategoriesContainer h3{
        margin: 10px 0;
        font-family: 'Oswald', sans-serif;
        font-size: 20px;
        font-weight: 400;
    }
}