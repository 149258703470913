.cardContainer {
    max-width: 345px;
    min-width: 250px;
    height: 340px;
    margin: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
