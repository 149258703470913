.carouselContainer {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
}

.carouselContainer img {
    margin: 0;
    padding: 0;
    width: 100%;
}