.productSiderContainer {
    display: block;
}

.productSiderContainer ul{
    background-color: #ffffff;
}

@media screen and (max-width: 768px) {
    .productSiderContainer {
        display: none;
    }
}