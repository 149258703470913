.bestSellersContainer{
    height: auto;
    width: 100%;
    margin: 10px 0;
}

.bestSellersTitle{
    width: 100%;
    clear: both;
    margin: 0;
    padding: 0;
}
