.badgeContainer {
    display: flex;
}

.badgeContainer img{
    width: 100px;
    height: 100px;
    margin: 15px;
}

.badgeContent {
    font-size: 0.8em;
}

.badgeContent h5 {
    font-weight: bold;
}