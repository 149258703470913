.productsContentContainer {
    background-color: #f5f4f8;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: row;
}

.productsByCategoriesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

@media screen and (max-width: 768px) {
    .productsContentContainer {
        background-color: #f5f4f8;
        padding: 0;
    }
    .productsByCategoriesContainer {
        margin: 0;
    }
}