.modalContainer {
    padding: 10px;
}

.modalContainer h6 {
    margin-top: 10px;
}

.contactUsToday {
   border-top: 1px dashed #1f79f640;
   margin-top: 30px;
}

@media screen and (max-width: 768px) {
.modalContainer {
    padding: 0;
}
}