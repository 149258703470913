.contactUsMessageContainer {
    padding: 20px;
    margin: 50px auto;
    max-width: 600px;
}

.successMessage {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 250px
}

.successMessageIcon {
    max-width: 75px;
}

.successMessageTitle {
    color: #3DC480;
    transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

.successMessageContent {
    color: #B8BABB;
    transform: translateY(25px);
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}