@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,500|Raleway:400,700,400italic,700italic);

.customizationProcessIcon{
    width: 100px;
    height: 100px;
    margin-left: 50px;
}

h4 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

ul,
ol {
    margin: 1em 0;
}

ol.process-chart {
    color: #000;
    counter-reset: item;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0 1em;
    padding: 0;
}

ol.process-chart > li,
ol.process-chart > li > div {
    position: relative;
}

ol.process-chart > li {
    background: linear-gradient(to right, rgba(0,0,0,0.25) 0%, transparent 25%);
    background-color: #fff;
    counter-increment: item;
    flex-basis: 0;
    flex-grow: 1;
    margin: 1em 1em 1em -1em;
    min-width: 11.25em;
    padding: 1em 1em 1em 2em;
}

ol.process-chart > li:before,
ol.process-chart > li > div:before {
    color: rgba(0,0,0,0.75);
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

ol.process-chart > li:before {
    content: 'Step';
    display: inline-block;
    font-weight: 300;
    left: 2em;
    letter-spacing: 0.1em;
    position: absolute;
    top: 1em;
    transform: rotate(90deg);
    transform-origin: 25% 50%;
}

ol.process-chart > li:after {
    border-bottom: 1em solid transparent;
    border-left: 1em solid #fff;
    border-right: 0 solid transparent;
    border-top: 1em solid transparent;
    content: '';
    height: 100%;
    position: absolute;
    right: -1em;
    top: 0;
    width: 0;
    z-index: 1;
}

ol.process-chart > li > div {
    padding: 0 0 0 1em;
}

ol.process-chart > li > div:before {
    content: counter(item, decimal-leading-zero);
    font-size: 3em;
    font-weight: 500;
    line-height: 1;
}

ol.process-chart > li:nth-of-type(6n+1) {
    background-color: #a2ed56;
}

ol.process-chart > li:nth-of-type(6n+1):after {
    border-left-color: #a2ed56;
}

ol.process-chart > li:nth-of-type(6n+2) {
    background-color: #83e4e2;
}

ol.process-chart > li:nth-of-type(6n+2):after {
    border-left-color: #83e4e2;
}

ol.process-chart > li:nth-of-type(6n+3) {
    background-color: #fd6470;
}

ol.process-chart > li:nth-of-type(6n+3):after {
    border-left-color: #fd6470;
}

ol.process-chart > li:nth-of-type(6n+4) {
    background-color: #fca858;
}

ol.process-chart > li:nth-of-type(6n+4):after {
    border-left-color: #fca858;
}

ol.process-chart > li:nth-of-type(6n+5) {
    background-color: #fddc32;
}

ol.process-chart > li:nth-of-type(6n+5):after {
    border-left-color: #fddc32;
}

ol.process-chart > li:nth-of-type(6n+6) {
    background-color: #ab9ace;
}

ol.process-chart > li:nth-of-type(6n+6):after {
    border-left-color: #ab9ace;
}

/*** MEDIA QUERIES ***/
@media only screen and ( max-width: 40em ) {

    ol.process-chart {
        margin: 1em 0 0 0;
        padding: 1em 0 0 0;
    }

    ol.process-chart > li {
        background: linear-gradient(rgba(0,0,0,0.25) 0%, transparent 25%);
        flex-basis: inherit;
        margin: -1em 0 1em 0;
        min-width: inherit;
        padding: 1em 1em 0 1em;
    }

    ol.process-chart > li:before {
        left: 1em;
        top: 2em;
    }

    ol.process-chart > li:after {
        border-bottom: 0 solid transparent;
        border-left: 1em solid transparent!important;
        border-right: 1em solid transparent;
        border-top: 1em solid #fff;
        bottom: -1em;
        height: 0;
        left: 0;
        right: inherit;
        top: inherit;
        width: 100%;
    }

    ol.process-chart > li > div {
        padding: 1em 1em 0 1em;
    }

    ol.process-chart > li:nth-of-type(6n+1):after {
        border-top-color: #a2ed56;
    }

    ol.process-chart > li:nth-of-type(6n+2):after {
        border-top-color: #83e4e2;
    }

    ol.process-chart > li:nth-of-type(6n+3):after {
        border-top-color: #fd6470;
    }

    ol.process-chart > li:nth-of-type(6n+4):after {
        border-top-color: #fca858;
    }

    ol.process-chart > li:nth-of-type(6n+5):after {
        border-top-color: #fddc32;
    }

    ol.process-chart > li:nth-of-type(6n+6):after {
        border-left-color: #ab9ace;
    }
}