.notificationBar {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    position: fixed;
    z-index: 1000;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color:#00A0C6;
    text-decoration:none;
    cursor:pointer;
}