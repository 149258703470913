.aboutUsContainer{
    height: auto;
    width: 100%;
    margin: 10px 0;
}

.aboutUsTitle{
    width: 100%;
    clear: both;
    margin: 0;
    padding: 0;
}

.aboutUsImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutUsContentContainer {
    border-right: 1px #888888 solid;
    font-size: 1.2em;
}

.aboutUsContentContainer p{
    padding: 0 50px;
}

@media screen and (max-width: 768px) {
    .aboutUsContentContainer {
        border-right: none;
        padding-left: 15px;
        font-size: 1em;
    }
    .aboutUsContentContainer p{
        padding: 0;
    }
}